export default {
    state: {
        products: [],
        page: 18
    },
    getters: {
        getCategoryProducts: state => state.products,
        getPage: state=>state.page
    },
    mutations: {
        setCategoryProducts(state, payload) {
            state.products = payload
        },
        setPage(state, payload){
            state.page = payload;
        }
    },
}
