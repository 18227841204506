import Vue from 'vue'
import axios from 'axios'

const params = new URLSearchParams(window.location.search);

let bot_id = null;
if (params.has('bot_id'))
    bot_id = params.get('bot_id');

const axiosIns = axios.create({
    //baseURL: "https://testtelegram.hippo.uz",
    headers: {
        //'x-webapp-session': '1067389207',
        //'x-webapp-bot': 1,
        'x-webapp-session': window.Telegram && window.Telegram.WebApp ? window.Telegram.WebApp.initData : null,
        'x-webapp-bot': bot_id
    }
});

Vue.prototype.$http = axiosIns

export default axiosIns
