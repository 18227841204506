import store from '../store'

// Замыкание
(function () {
    /**
     * Корректировка округления десятичных дробей.
     *
     * @param {String}  type  Тип корректировки.
     * @param {Number}  value Число.
     * @param {Integer} exp   Показатель степени (десятичный логарифм основания корректировки).
     * @returns {Number} Скорректированное значение.
     */
    function decimalAdjust(type, value, exp) {
        // Если степень не определена, либо равна нулю...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value)
        }
        value = +value
        exp = +exp
        // Если значение не является числом, либо степень не является целым числом...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN
        }
        // Сдвиг разрядов
        value = value.toString()
            .split('e')
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
        // Обратный сдвиг
        value = value.toString()
            .split('e')
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
    }

    // Десятичное округление к ближайшему
    if (!Math.round10) {
        Math.round10 = function (value, exp) {
            return decimalAdjust('round', value, exp)
        }
    }

    // Десятичное округление вниз
    if (!Math.floor10) {
        Math.floor10 = function (value, exp) {
            return decimalAdjust('floor', value, exp)
        }
    }

    // Десятичное округление вверх
    if (!Math.ceil10) {
        Math.ceil10 = function (value, exp) {
            return decimalAdjust('ceil', value, exp)
        }
    }
})()

let formatNumberMinorPart = (value, len) => {
    while (value.length < len) {
        value = `${value}0`
    }

    return value.substr(0, len)
}

export default {
    formatNumberMajorPart(value) {
        let result = ''
        value = value.split('')
            .reverse()
            .join('')
        for (let i = 0; i < value.length; i++) {
            if (i > 0 && i % 3 === 0) {
                result = `${result} `
            }
            result = `${result}${value[i]}`
        }

        if (result.length && result[result.length - 1] === ' ') {
            result = result.substr(0, result.length - 1)
        }

        return result.split('')
            .reverse()
            .join('')
    },

    decimalToString(value) {
        let mathRound = store.getters.getMathRound || 4

        value = Math.round10(value, -mathRound)

        let major = value.toString()
        let commaIndex = major.replace(',', '.')
            .indexOf('.')

        // minor part
        let minor = ''
        if (commaIndex > -1) {
            minor = major.substr(commaIndex + 1)
            major = major.substr(0, commaIndex)
        }

        major = this.formatNumberMajorPart(major)
        while (minor.length > 0) {
            let lastChar = minor[minor.length - 1]
            if (lastChar === '.') {
                minor = ''
            } else if (lastChar === '0') {
                minor = minor.substr(0, minor.length - 1)
            } else {
                break
            }
        }

        if (minor.length === 0) {
            return major
        }

        return `${major}.${minor}`
    },

    apiDateTimeFormat(datetime){
        if (datetime) {
            let day = datetime.getDate().toString();
            day = day.length === 1 ? `0${day}` : day;

            let month = (datetime.getMonth() + 1).toString();
            month = month.length === 1 ? `0${month}` : month;

            let year = datetime.getFullYear().toString();

            let hr = datetime.getHours();
            let min = datetime.getMinutes();
            let sek = datetime.getSeconds();

            if (min < 10) {
                min = "0" + min;
            }
            let date = `${year}-${month}-${day}`;
            let time = `${hr}:${min}:${sek}`;

            return date + ' ' + time;
        }
        return datetime;
    },
    apiDateFormat(date){
        if (date) {
            let day = date.getDate().toString();
            day = day.length === 1 ? `0${day}` : day;

            let month = (date.getMonth() + 1).toString();
            month = month.length === 1 ? `0${month}` : month;

            let year = date.getFullYear().toString();

            return `${year}-${month}-${day}`;
        }
        return date;
    }
}
