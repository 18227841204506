export default {
    state: {
        categories: [],
        categoriesTree: [],
    },
    getters: {
        getCategoriesTree: state => state.categoriesTree,
    },
    mutations: {
        setCategories(state, payload) {
            state.categories = payload
        },
        setCategoriesTree(state, payload) {
            state.categoriesTree = payload
        }
    }
}
