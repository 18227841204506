import ContractorService from '../../services/contractor.service'

export default {
    state: {
        contractors: [],
        page: 15
    },
    getters: {
        getContractors: state => state.contractors,
        getPageContractors: state => {
            return state.page
        },
    },
    mutations: {
        setPageContractors(state, payload) {
            state.page = payload
        },
        setContractors(state, payload) {
            state.contractors = payload
        },
        pushUpdateContractor: (state, payload) => {
            const {id, contacts, debts, inn, labels, name, type, location} = payload
            const editingItemIndex = state.contractors.findIndex((contractor) => contractor.id === id);

            if (editingItemIndex > -1) {
                state.contractors[editingItemIndex].contacts = contacts;
                state.contractors[editingItemIndex].debts = debts;
                state.contractors[editingItemIndex].inn = inn;
                state.contractors[editingItemIndex].labels = labels;
                state.contractors[editingItemIndex].name = name;
                state.contractors[editingItemIndex].type = type;
                state.contractors[editingItemIndex].location = location;
            } else {
                state.contractors.push(payload)
            }
        },
    },
    actions: {
        getAllContractors({commit}) {
            return new Promise((resolve, reject) => {
                ContractorService.getAllContractors()
                    .then(res => {
                        if (res && res.data) {
                            commit('setContractors', res.data)
                            resolve(res.data)
                        } else {
                            reject()
                        }
                    })
                    .catch(err => {
                        reject()
                    })
            })
        },
    }
}
