import axiosIns from "@/libs/axios";

class ContractorService {
    getAllContractors(params) {
        return axiosIns.get('/api/contractor/get', {params: params})
    }
    updateContractor(contractor_id, payload) {
        return axiosIns.post(`/api/contractor/update/${contractor_id}`, payload)
    }
}

export default new ContractorService()
