import Vue from 'vue'
import KitCut from '../plugins/kitcut';
import formatter from '../plugins/formatter';
import moment from "moment";



Vue.filter('kit_cut', (text, length) => {
    return KitCut.kitCutText(text, length)
})

Vue.filter("decimal", (value) => {
    let val = +value;
    return formatter.decimalToString(val);
});

Vue.filter('datetime', (date) => {
    const format = 'DD.MM.YYYY HH:mm:ss'
    return (moment(date).format(format))
});

Vue.filter('date', (date) => {
    const format = 'DD.MM.YYYY'
    return (moment(date).format(format))
});

Vue.filter("api_datetime_format", (datetime) => {
    return formatter.apiDateTimeFormat(datetime);
});

Vue.filter("api_date_format", (datetime) => {
    return formatter.apiDateFormat(datetime);
});
