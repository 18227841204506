<template>
    <div
        id="app"
        class="h-100"
        :class="[skinClasses]"
    >
        <LayoutVertical>
            <router-view/>
        </LayoutVertical>

        <scroll-to-top v-if="enableScrollToTop"/>
    </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

import {$themeColors, $themeBreakpoints, $themeConfig} from '@themeConfig'
import {provideToast} from 'vue-toastification/composition'
import {watch} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import {useWindowSize, useCssVar} from '@vueuse/core'

import store from '@/store'
import {mapGetters, mapMutations} from "vuex";

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')

import OrderService from "../src/services/order.service"

export default {
    components: {
        LayoutVertical,
        ScrollToTop,
    },
    computed: {
        ...mapGetters({
            orderId: 'getOrderId',
        }),
        layout() {
            if (this.$route.meta.layout === 'full') return 'layout-full'
            return `layout-${this.contentLayoutType}`
        },
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type
        },
    },
    beforeCreate() {
        // Set colors in theme
        const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = colors.length; i < len; i++) {
            $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
        }

        // Set Theme Breakpoints
        const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = breakpoints.length; i < len; i++) {
            $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
        }

        // Set RTL
        const {isRTL} = $themeConfig.layout
        document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    },
    setup() {
        const {skin, skinClasses} = useAppConfig()
        const {enableScrollToTop} = $themeConfig.layout

        if (skin.value === 'dark') document.body.classList.add('dark-layout')

        provideToast({
            hideProgressBar: true,
            closeOnClick: false,
            closeButton: false,
            icon: false,
            timeout: 3000,
            transition: 'Vue-Toastification__fade',
        })

        // Set Window Width in store
        store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
        const {width: windowWidth} = useWindowSize()
        watch(windowWidth, val => {
            store.commit('app/UPDATE_WINDOW_WIDTH', val)
        })

        return {
            skinClasses,
            enableScrollToTop,
        }
    },
    mounted() {
        let url = new URL(window.location.href);
        let isExistApp = url.hash.split('/').includes("app");
        if (!isExistApp) {
            if (this.$route.name !== 'new-order')
                this.$router.push({name: 'new-order'}).then(res => {
                }).catch(err => {
                });
        }
        this.$store.dispatch("getAllProducts");
        this.$store.dispatch("getMe");
        this.$store.dispatch("getAllContractors");
    },
    methods: {
        ...mapMutations(['setOrder']),
    },
    created() {
        if (this.orderId) {
            OrderService.getOrder(this.orderId).then(res => {
                if (res && res.data) {
                    this.setOrder(res.data)
                }
            }).catch(err => {
            })
        }
    }
}
</script>
