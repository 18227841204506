import OrderItemService from "@/services/order.item.service";
import OrderService from "@/services/order.service";
import formatter from '../../plugins/formatter';

export default {
    state: {
        order: null,
    },
    getters: {
        getOrder: state => state.order,
        totalOrder: state => {
            return state.order && state.order.items.reduce((total, order_item) => {
                return total + (order_item.quantity * order_item.price_amount)
            }, 0);
        },
        getOrderId: function (state) {
            if (state.order) return state.order.id
            let order_id_from_storage = localStorage.getItem('order_id')
            if (order_id_from_storage) return order_id_from_storage
            return null
        },
        countOrderItems: function (state) {
            return state.order ? state.order.items.length : 0;
        }
    },
    mutations: {
        setOrder(state, payload) {
            state.order = payload
            if (payload)
                localStorage.setItem('order_id', payload.id);
            else
                localStorage.removeItem('order_id');
        },
        setOrderItems(state, payload) {
            state.order.items = payload
        },
    },
    actions: {
        createOrderItem({commit}, payload) {
            return new Promise((resolve, reject) => {
                OrderItemService.createOrderItem(payload).then(res => {
                    if (res) {
                        commit('setOrder', res.data);
                        resolve(res.data)
                    } else {
                        reject()
                    }
                }).catch(err => {
                    reject()
                })
            });
        },
        registerOrder({commit}, payload) {
            return new Promise((resolve, reject) => {
                OrderService.registerOrder(payload).then(res => {
                    if (res) {
                        commit('setOrder', res.data);
                        resolve(res.data)
                    } else
                        reject()
                }).catch(err => {
                    reject()
                })
            });
        },
        updateOrderItem({commit}, payload) {
            return new Promise((resolve, reject) => {
                OrderItemService.updateOrderItem(payload).then(res => {
                    if (res) {
                        commit('setOrder', res.data);
                        resolve(res.data)
                    } else
                        reject()
                }).catch(err => {
                    reject()
                })
            })
        },
        deleteOrder({commit, getters}, order_id) {
            return new Promise((resolve, reject) => {
                OrderService.deleteOrder(order_id).then(res => {
                    if (res) {
                        commit('setOrder', null)
                        resolve(res.data)
                    } else {
                        reject()
                    }
                }).catch(err => {
                    reject()
                })
            })
        },
        deleteOrderItem({commit, getters, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                OrderItemService.deleteOrderItem(payload).then(res => {
                    if (res) {
                        let order_items = getters.getOrder.items.filter(order_item => order_item.id !== payload.item_id);
                        commit('setOrderItems', order_items)
                        if (order_items.length === 0) {
                            dispatch('deleteOrder', getters.getOrder.id);
                        }
                        resolve(res.data)
                    } else {
                        reject()
                    }
                }).catch(err => {
                    reject()
                })
            })
        },
        updateOrder({commit}, {order_id, payload}) {
            return new Promise((resolve, reject) => {
                OrderService.updateOrder(order_id, payload).then(res => {
                    if (res.data) {
                        resolve(res.data)
                    } else {
                        reject()
                    }
                }).catch(err => {
                    reject()
                })
            })
        },
        checkoutOrder({commit}, order_id) {
            return new Promise((resolve, reject) => {
                OrderService.checkoutOrder(order_id).then(res => {
                    if (res.data) {
                        commit('setOrder', null)
                        resolve(res.data)
                    } else {
                        reject()
                    }
                }).catch(err => {
                    reject()
                })
            })
        },
        incrementQuantity({getters, dispatch}, {product, selectedPackageMeasurement}) {
            let orderItem = getters.getOrder ? getters.getOrder.items.find(order_item => order_item.global_item_id === product.item.id) : null

            if (orderItem) {
                return dispatch('updateOrderItem', {
                    item_id: orderItem.id,
                    order_id: getters.getOrder.id,
                    quantity: orderItem.quantity + selectedPackageMeasurement.quantity,
                })
            } else {
                return dispatch('createOrderItem', {
                    order_id: getters.getOrder.id,
                    item: {
                        global_item_id: product.item.id,
                        global_price_id: product.price.common_price.id,
                        quantity: selectedPackageMeasurement.quantity,
                    }
                })
            }
        },
        decrementQuantity({getters, dispatch}, {product, selectedPackageMeasurement}) {
            let orderItem = getters.getOrder ? getters.getOrder.items.find(order_item => order_item.global_item_id === product.item.id) : null
            if (!orderItem)
                return;

            if (orderItem.quantity === selectedPackageMeasurement.quantity || selectedPackageMeasurement.quantity > orderItem.quantity) {
                return dispatch('deleteOrderItem', {order_id: getters.getOrder.id, item_id: orderItem.id})
            }

            if (orderItem.quantity > selectedPackageMeasurement.quantity) {
                return dispatch('updateOrderItem', {
                    item_id: orderItem.id,
                    order_id: getters.getOrder.id,
                    quantity: orderItem.quantity - selectedPackageMeasurement.quantity,
                })
            }
        },
        addProduct({getters, dispatch}, payload) {
            if (getters.getOrder) {
                return dispatch('createOrderItem', {
                    order_id: getters.getOrder.id,
                    item: {
                        global_item_id: payload.product.item.id,
                        global_currency_id: payload.product.price.common_price.global_currency_id,
                        price_amount: payload.product.price.common_price.amount,
                        quantity: payload.quantity,
                    }
                })
            } else {
                let current_date = formatter.apiDateFormat(new Date());
                return dispatch('registerOrder', {
                    contractor_id: payload.contractor.id,
                    order: {
                        number: String(new Date().getTime()).slice(3),
                        date: current_date
                    },
                    contract: null,
                    expires_on: null,
                    items: [{
                        global_item_id: payload.product.item.id,
                        global_currency_id: payload.product.price.common_price.global_currency_id,
                        price_amount: payload.product.price.common_price.amount,
                        quantity: payload.quantity,
                    }]
                })
            }
        }
    }
}
