import axiosIns from "@/libs/axios";

class OrderItemService {
    createOrderItem(payload) {
        return axiosIns.post(`/api/OrderItem/register`, payload)
    }

    updateOrderItem(payload) {
        return axiosIns.post(`/api/OrderItem/update`, payload)
    }

    deleteOrderItem(payload) {
        return axiosIns.post(`/api/OrderItem/delete`, payload)
    }
}

export default new OrderItemService()
