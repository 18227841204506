import ProductService from "../../services/product.service";

export default {
    state: {
        products: [],
        loadedProducts: false,
        pageFavoriteProducts: 15,
        pageAllProducts: 15
    },
    getters: {
        getProducts: state => {
            return state.products
        },
        getPageFavoriteProducts: state => {
            return state.pageFavoriteProducts
        },
        getPageAllProducts: state => {
            return state.pageAllProducts
        },
        isLoadedProducts: state => {
            return state.loadedProducts
        },
        favoriteProducts: state => {
            return state.products ? state.products.filter(dd => dd.item.is_favorite) : []
        },
    },
    mutations: {
        setPageFavoriteProducts(state, payload) {
            state.pageFavoriteProducts = payload
        },
        setPageAllProducts(state, payload) {
            state.pageAllProducts = payload
        },
        setProducts(state, payload) {
            state.products = payload
        },
        setLoadedProducts(state, payload) {
            state.loadedProducts = payload
        }
    },
    actions: {
        getAllProducts({commit, getters}, params) {
            commit('setLoadedProducts', false)
            return new Promise((resolve, reject) => {
                ProductService.getAllProducts(params).then(res => {
                    if (res) {
                        commit('setProducts', res.data.data)
                        commit('setCategories', res.data.categories)
                        commit('setCategoriesTree', res.data.categoriesTree)
                        resolve(res.data)
                    } else {
                        reject()
                    }
                }).catch(err => {
                    reject()
                }).finally(() => {
                    commit('setLoadedProducts', true)
                })
            })
        }
    }
}
