import axiosIns from "@/libs/axios";

class ProductService {
    getItem(id) {
        return axiosIns.get(`/api/item/get/${id}`)
    }

    getAllProducts(params) {
        return axiosIns.get(`/api/item/get`, {
            params: params
        })
    }
}

export default new ProductService()
