import BotService from "../../services/bot.service"

export default {
    state: {
        current_bot: [],
    },
    getters: {
        getCurrentBot: state => state.current_bot,
    },
    mutations: {
        setCurrentBot(state, payload) {
            state.current_bot = payload
        }
    },
    actions: {
        getMe({commit}) {
            return new Promise((resolve, reject) => {
                BotService.getMe().then(res => {
                    if (res && res.data) {
                        commit('setCurrentBot', res.data)
                        resolve(res.data)
                    } else {
                        reject()
                    }
                }).catch(err => {
                    reject()
                });
            })
        }
    }
}
