import axiosIns from "@/libs/axios";

class OrderService {
    registerOrder(payload) {
        return axiosIns.post(`/api/order/post`, payload)
    }

    getAllOrders(params) {
        return axiosIns.get(`/api/order/get`, {params: params})
    }

    getAllOrdersCount(params) {
        return axiosIns.get(`/api/order/get/count`, {params: params})
    }

    getOrder(order_id) {
        return axiosIns.get(`/api/order/get/${order_id}`)
    }

    deleteOrder(id) {
        return axiosIns.post(`/api/order/delete`, {id: id})
    }

    checkoutOrder(id) {
        return axiosIns.post(`/api/order/checkout`, {id})
    }

    updateOrder(order_id, payload) {
        return axiosIns.post(`/api/order/update/${order_id}`, payload)
    }
}

export default new OrderService()
