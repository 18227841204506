import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    routes: [{
        path: '/',
        redirect: '/app/new-order',
        component: () => import(/* webpackChunkName: "AppHome" */ '@/views/app'),
        children: [
            {
                path: 'app/new-order',
                name: 'new-order',
                component: () => import(/* webpackChunkName: "all-products" */ './views/app/NewOrder.vue'),
            },
            {
                path: 'app/product/details/:id',
                name: 'product-details',
                component: () => import(/* webpackChunkName: "product-details" */ '@/views/app/ProductDetails.vue'),
            },
            {
                path: 'app/category',
                name: 'category',
                component: () => import(/* webpackChunkName: "category" */ './views/app/Category.vue'),
            },
            {
                path: 'app/order',
                component: () => import(/* webpackChunkName: "order" */ './views/app/user/order'),
                meta: { isAdmin: false },
                children: [
                    {
                        path: '',
                        name: 'user-orders',
                        meta: { isAdmin: false },
                        component: () => import(/* webpackChunkName: "user-orders" */ './views/app/user/order/UserOrders'),
                    },
                    {
                        path: ':id',
                        name: 'user-order-view',
                        meta: { isAdmin: false },
                        component: () => import(/* webpackChunkName: "user-order-view" */ './views/app/user/order/UserOrderView'),
                    },
                ]
            },
            {
                path: 'app/contractor',
                component: () => import(/* webpackChunkName: "contractor" */ './views/app/contractor'),
                redirect: '/app/contractor/all',
                children: [
                    {
                        path: 'all',
                        name: 'all-contractors',
                        component: () => import(/* webpackChunkName: "all-contractors" */ './views/app/contractor/AllContractors'),
                    },
                    {
                        path: ':id',
                        name: 'contractor-view',
                        component: () => import(/* webpackChunkName: "contractor-view" */ './views/app/contractor/ContractorView'),
                    },
                ]
            },
            {
                path: 'app/set-location',
                name: 'set-location',
                component: () => import(/* webpackChunkName: "all-products" */ './components/map/LeafletBasic'),
            },
            {
                path: 'app/settings',
                name: 'settings',
                component: () => import(/* webpackChunkName: "settings" */ './views/app/Settings.vue'),
            },
        ]
    }],
})

router.afterEach(() => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

router.beforeEach((to, from, next) => {
    if (!to.query.bot_id && from.query.bot_id) {
        to.query.bot_id = from.query.bot_id
        next({
            path: to.path,
            query: to.query
        })
    } else {
        next()
    }
})

export default router
