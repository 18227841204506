import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import order from './modules/order'
import category from './modules/category'
import product from './modules/product'
import category_page from './modules/category_page'
import bot from './modules/bot'
import contractor from './modules/contractor'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    order,
    category,
    product,
    category_page,
    bot,
    contractor,
  },
  strict: process.env.DEV,
})
