import Vue from 'vue'
import App from './App.vue'

import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
//import "vue-select/dist/vue-select.css";

import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue)

import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)

Vue.use(VueCompositionAPI)
import './filters'

require('@core/scss/core.scss')

require('@/assets/scss/style.scss')

Vue.config.productionTip = false

window.Telegram && window.Telegram.WebApp.expand();
window.Telegram && window.Telegram.WebApp.enableClosingConfirmation();

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
